import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import EducatorApplication from 'components/EducatorApplication';
import SEO from 'components/seo';
import get from 'lodash.get';

export default function EducatorApplicationPage(props) {
    let {
        data: { allContentfulEducatorApplication },
    } = props;
    let item = allContentfulEducatorApplication.nodes[0];
    return (
        <Layout>
            <SEO
                description={get(
                    item,
                    'seoListing.description.description'
                )}
                title={get(
                    item,
                    'seoListing.seoTitle'
                )}
                slug={'educator-application'}
            />
            <EducatorApplication page={item} />
        </Layout>
    );
}

export const query = graphql`
    query($locale: String) {
        allContentfulEducatorApplication(
            filter: {
                id: { in: ["f37a8ed6-7ae4-503a-ad70-9ffa5ea91bd2", "0d851ab9-500f-50a0-b827-3e52a03b6103"] }
                node_locale: { eq: $locale }
            }
        ) { nodes {
            id
            title
            subtitle {
                subtitle
            }
            topTextItems {
                items {
                    text
                    title
                }
                title
            }
            background {
                localFile {
                    publicURL
                }
            }
            node_locale
            seoListing {
                ...ContentfulSeoListingFields
            }
        } }
    }
`;
