import React from 'react'
import get from 'lodash.get'
import { navigate } from 'gatsby-link'
import urls from 'helpers/url'
import { injectIntl } from 'gatsby-plugin-react-intl'

import {
    Spacing,
    Container,
    SharedStyles,
    Fieldset,
    FieldsGroup,
    FieldInput,
    FieldSubmit,
    FieldRadios,
    FieldTextarea,
    EducatorApplication,
} from '../kenra-storybook/index'

const { StSectionInfo, StTitle } = SharedStyles

function convertParametersToJsonArray(data) {
    let jsonArray = []
    Object.keys(data).map(key => {
        jsonArray.push({
            name: key,
            value: data[key],
        })
    })
    return jsonArray
}

function formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
    if (match) {
        return [match[2], '-', match[3], '-', match[4]].join('')
    }
    return null
}

const EducatorApplicationPage = ({ intl, page }) => {
    const [state, setState] = React.useState({})
    const toEmail = process.env.GATSBY_SENDGRID_SUPPORT_EMAIL
    const handleChange = e => {
        if (e.target.name === 'Cell Phone') {
            setState({
                ...state,
                [e.target.name]:
                    formatPhoneNumber(e.target.value) || e.target.value,
            })
        } else {
            setState({ ...state, [e.target.name]: e.target.value })
        }
    }

    const handleRadioChange = label => {
        setState({ ...state, salonOwnerRenter: label })
    }

    const handleSubmit = e => {
        e.preventDefault()
        const form = e.target
        fetch('/.netlify/functions/sendTicketEmail', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: JSON.stringify({
                toEmail: toEmail,
                subject: 'Kenra Educator Application Form Submission',
                body: convertParametersToJsonArray({ ...state }),
                email: state.Email
            }),
        })
            .then(response => {
                if (!response.ok) {
                    if (response.status === 406) {
                        throw new Error(
                            intl.formatMessage({
                                id: 'form-submission-robot-error',
                            })
                        )
                    }
                    throw new Error(
                        intl.formatMessage({ id: 'form-submission-error' })
                    )
                }
            })
            .then(() => navigate(form.getAttribute('action')))
            .catch(error => {
                alert(error.message)
                document.getElementById('educatorapplicationform').reset()
            })
    }

    let { title, subtitle, topTextItems, logo, background } = page

    return (
        <>
            <Spacing
                style={{
                    backgroundImage: `url("${get(
                        background,
                        'localFile.publicURL'
                    )}")`,
                }}
            >
                <Container useLargeWidth>
                    <EducatorApplication colorBlack items={topTextItems} />
                </Container>
            </Spacing>

            <Spacing>
                <StSectionInfo>
                    <Container>
                        <StTitle colorBlack styleMedium>
                            <h2>{title}</h2>
                        </StTitle>
                        <p>{get(subtitle, 'subtitle')}</p>
                    </Container>
                </StSectionInfo>
            </Spacing>
            <Container useLargeWidth>
                <hr />
            </Container>

            <Spacing>
                <Container useLargeWidth>
                    <form
                        id="educatorapplicationform"
                        name="educatorApplication"
                        method="POST"
                        action={urls.submitted}
                        onSubmit={handleSubmit}
                    >
                        <p hidden>
                            <label>
                                Don’t fill this out:
                                <input
                                    name="bot-field"
                                    onChange={handleChange}
                                />
                            </label>
                        </p>
                        <StSectionInfo>
                            <Container>
                                <StTitle colorBlack styleMedium>
                                    <h2>
                                        {intl.formatMessage({
                                            id: 'education-form-pii-title',
                                        })}
                                    </h2>
                                </StTitle>
                            </Container>
                        </StSectionInfo>
                        <Fieldset
                            legend={intl.formatMessage({
                                id: 'form-first-last-name-title',
                            })}
                        >
                            <FieldInput
                                type="text"
                                id="pii-id-1"
                                name="First & Last Name"
                                onChange={handleChange}
                                required
                            />
                        </Fieldset>
                        <Fieldset
                            legend={intl.formatMessage({
                                id: 'education-form-homeaddress-title',
                            })}
                        >
                            <FieldInput
                                type="text"
                                id="pii-id-2"
                                name="Home Address"
                                onChange={handleChange}
                                required
                            />
                        </Fieldset>
                        <Fieldset
                            legend={intl.formatMessage({
                                id: 'education-form-citystatezip-title',
                            })}
                        >
                            <FieldInput
                                type="text"
                                id="pii-id-3"
                                name="Home Address City/State/Zip"
                                onChange={handleChange}
                                required
                            />
                        </Fieldset>
                        <Fieldset
                            legend={intl.formatMessage({
                                id: 'education-form-cellphone-title',
                            })}
                        >
                            <FieldInput
                                type="tel"
                                id="pii-id-4"
                                label="###-###-####"
                                size={12}
                                name="Cell Phone"
                                pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                                onChange={handleChange}
                                required
                            />
                        </Fieldset>
                        <Fieldset
                            legend={intl.formatMessage({
                                id: 'form-email-title',
                            })}
                        >
                            <FieldInput
                                type="email"
                                id="pii-id-5"
                                name="Email"
                                onChange={handleChange}
                                required
                            />
                        </Fieldset>
                        <Fieldset
                            legend={intl.formatMessage({
                                id: 'education-form-license-number',
                            })}
                        >
                            <FieldInput
                                type="text"
                                id="pii-id-6"
                                name="Salon License Number"
                                onChange={handleChange}
                                required
                            />
                        </Fieldset>
                        <Fieldset
                            legend={intl.formatMessage({
                                id: 'education-form-license-expiration',
                            })}
                        >
                            <FieldInput
                                type="text"
                                id="pii-id-7"
                                name="State Issued Name & ExpiryDate"
                                onChange={handleChange}
                                required
                            />
                        </Fieldset>
                        <StSectionInfo>
                            <Container>
                                <StTitle colorBlack styleMedium>
                                    <h2>
                                        {intl.formatMessage({
                                            id:
                                                'education-form-salonwork-title',
                                        })}
                                    </h2>
                                </StTitle>
                            </Container>
                        </StSectionInfo>
                        <Fieldset
                            legend={intl.formatMessage({
                                id: 'form-salon-name-title',
                            })}
                        >
                            <FieldInput
                                type="text"
                                id="salon-id-1"
                                name="Salon Name"
                                onChange={handleChange}
                            />
                        </Fieldset>
                        <Fieldset
                            legend={intl.formatMessage({
                                id: 'education-form-salonaddress-title',
                            })}
                        >
                            <FieldInput
                                type="text"
                                id="salon-id-2"
                                name="Salon Address"
                                onChange={handleChange}
                            />
                        </Fieldset>
                        <Fieldset
                            legend={intl.formatMessage({
                                id: 'education-form-salon-citystatezip-title',
                            })}
                        >
                            <FieldInput
                                type="text"
                                id="salon-id-3"
                                name="Salon Address City/State/Zip"
                                onChange={handleChange}
                            />
                        </Fieldset>
                        <Fieldset
                            legend={intl.formatMessage({
                                id: 'education-form-color-brand-question',
                            })}
                        >
                            <FieldInput
                                type="text"
                                id="salon-id-4"
                                name="Salon's Color Brands"
                                onChange={handleChange}
                            />
                        </Fieldset>
                        <Fieldset
                            legend={intl.formatMessage({
                                id: 'education-form-own-question',
                            })}
                        >
                            <FieldInput
                                type="text"
                                id="salon-id-5"
                                name="Salon Own or Rent"
                                onChange={handleChange}
                            />
                        </Fieldset>
                        <Fieldset
                            legend={intl.formatMessage({
                                id: 'education-form-knowledge-question',
                            })}
                            subLegend={intl.formatMessage({
                                id: 'education-form-knowledge-subquestion',
                            })}
                        >
                            <FieldInput
                                type="text"
                                id="salon-id-6"
                                name="Other Brand Knowledge"
                                onChange={handleChange}
                            />
                        </Fieldset>
                        <Fieldset
                            legend={intl.formatMessage({
                                id: 'education-form-day-question',
                            })}
                        >
                            <FieldInput
                                type="text"
                                id="salon-id-7"
                                name="Available Days"
                                onChange={handleChange}
                            />
                        </Fieldset>
                        <Fieldset
                            legend={intl.formatMessage({
                                id: 'education-form-language-question',
                            })}
                            subLegend={intl.formatMessage({
                                id: 'education-form-language-subquestion',
                            })}
                        >
                            <FieldInput
                                type="text"
                                id="salon-id-8"
                                name="Languages"
                                onChange={handleChange}
                            />
                        </Fieldset>
                        <StSectionInfo>
                            <Container>
                                <StTitle colorBlack styleMedium>
                                    <h2>
                                        {intl.formatMessage({
                                            id: 'education-form-bio-title',
                                        })}
                                    </h2>
                                </StTitle>
                            </Container>
                        </StSectionInfo>
                        <Fieldset
                            legend={intl.formatMessage({
                                id: 'education-form-website-question',
                            })}
                        >
                            <FieldInput
                                type="text"
                                id="bio-id-1"
                                name="Instagram/Website"
                                onChange={handleChange}
                            />
                        </Fieldset>
                        <Fieldset
                            legend={intl.formatMessage({
                                id: 'education-form-background-question',
                            })}
                        >
                            <FieldInput
                                type="text"
                                id="bio-id-2"
                                name="Beauty Background"
                                onChange={handleChange}
                            />
                        </Fieldset>
                        <Fieldset
                            legend={intl.formatMessage({
                                id: 'education-form-length-question',
                            })}
                        >
                            <FieldInput
                                type="text"
                                id="bio-id-3"
                                name="Time Using Kenra Products"
                                onChange={handleChange}
                            />
                        </Fieldset>
                        <Fieldset
                            legend={intl.formatMessage({
                                id: 'education-form-brands-question',
                            })}
                        >
                            <FieldInput
                                type="text"
                                id="bio-id-4"
                                name="Brands Used"
                                onChange={handleChange}
                            />
                        </Fieldset>
                        <Fieldset
                            legend={intl.formatMessage({
                                id: 'education-form-mentors-question',
                            })}
                        >
                            <FieldInput
                                type="text"
                                id="bio-id-5"
                                name="Mentors"
                                onChange={handleChange}
                            />
                        </Fieldset>
                        <Fieldset
                            legend={intl.formatMessage({
                                id: 'education-form-contract-question',
                            })}
                        >
                            <FieldInput
                                type="text"
                                id="bio-id-6"
                                name="Haircare Company Contract"
                                onChange={handleChange}
                            />
                        </Fieldset>
                        <Fieldset
                            legend={intl.formatMessage({
                                id: 'education-form-work-question',
                            })}
                        >
                            <FieldInput
                                type="text"
                                id="bio-id-7"
                                name="Work Done In Salon"
                                onChange={handleChange}
                            />
                        </Fieldset>
                        <Fieldset
                            legend={intl.formatMessage({
                                id: 'education-form-award-question',
                            })}
                        >
                            <FieldInput
                                type="text"
                                id="bio-id-8"
                                name="Awards Received"
                                onChange={handleChange}
                            />
                        </Fieldset>
                        <Fieldset
                            legend={intl.formatMessage({
                                id: 'education-form-skills-question',
                            })}
                            subLegend={intl.formatMessage({
                                id: 'education-form-skills-subquestion',
                            })}
                        >
                            <FieldInput
                                type="text"
                                id="bio-id-9"
                                name="Strongest Skills"
                                onChange={handleChange}
                            />
                        </Fieldset>
                        <Fieldset
                            legend={intl.formatMessage({
                                id: 'education-form-tool-question',
                            })}
                            subLegend={intl.formatMessage({
                                id: 'education-form-tool-subquestion',
                            })}
                        >
                            <FieldInput
                                type="text"
                                id="bio-id-10"
                                name="Most Used Tools"
                                onChange={handleChange}
                            />
                        </Fieldset>

                        <FieldSubmit>
                            <input
                                value={intl.formatMessage({
                                    id: 'form-submit-button',
                                })}
                                type="submit"
                            />
                        </FieldSubmit>
                    </form>
                </Container>
            </Spacing>
        </>
    )
}

export default injectIntl(EducatorApplicationPage)
